import styled from 'styled-components';

export const Layout = styled.div`
  background: #e8e8ea;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px 0;
`;

export const InfoCard = styled.div`
  background: white;
  padding: 30px;
  width: 100%;
  max-width: 900px;
  border-radius: 16px;
  position: relative;
  min-height: 400px;
`;

export const Title = styled.h1``;
